@use "~@els/els-styleguide-core/scss/settings/spaces";
@use "~@els/els-styleguide-core/scss/settings/keylines";
@use "~@els/els-styleguide-core/scss/settings/colors";
@use "~@els/els-styleguide-core/scss/tooling/sass-mq-config";

.c-hesi-order-cart {
  margin-left: spaces.$els-base-space * 6 !important;
  margin-right: spaces.$els-base-space * 6 !important;
  @include sass-mq-config.mq($until: mobile) {
    margin-left: spaces.$els-base-space * 1.5 !important;
    margin-right: spaces.$els-base-space * 1.5 !important;
  }
  @include sass-mq-config.mq($until: tablet) {
    margin-left: spaces.$els-base-space * 1.5 !important;
    margin-right: spaces.$els-base-space * 1.5 !important;
  }

  .c-els-tab-group {
    &__item--active {
      box-shadow: 0 -4px 0 0 colors.$els-color-primary inset !important;

      .c-els-tab-group__text {
        color: colors.$els-color-primary;
        pointer-events: none;
      }
    }
    &__text {
      pointer-events: none;
    }
    &__item:hover {
      box-shadow: none;
    }
    &__panel {
        padding: spaces.$els-space-2x 0 0 0;
    }
  }
}

.c-hesi-order-cart-review {
  &__tax-infor {
    text-align: right;
  }
  .max-btn-width {
    width: spaces.$els-base-space * 18;
  }
  .c-els-table__cell--expanded {
    border-bottom: none !important; 
  }
  
  .c-els-table__cell--field-expanded {
    padding-top: 0;
    padding-bottom: 0;
  }
  &-table__expand {
    &__column {
      &--0, &--1 {
        border-bottom: none;
      }
    }
    &__column--2 {
      padding-bottom: spaces.$els-base-space * 5;
    }
    &__column--latest {
      border-bottom: none;
      .c-hesi-order-cart-review-table__expand {
        &__column--2, &__column--3, &__column--4, &__column--5, &__column--6 {
          border-bottom: none;
        }
      }
    }
  }
  &-table, &-table__expand {
    &__column {
      &--0 {
        width: 0;
      }

      &--1 {
        width: 30%;
      }

      &--2 {
        width: 20%;
      }

      &--3 {
        width: 17%;
      }

      &--4 {
        width: 16%;
      }

      &--5 {
        width: 16%;
      }

      &--6 {
        width: 15%;
      }
    }
  }
}

.c-hesi-order-mini-cart{
  &-table {
    &__column {
      &--0 {
        width: 24%;
      }

      &--1 {
        width: 27%;
      }

      &--2 {
        width: 20%;
      }

      &--3 {
        width: 29%;
      }
    }
  }
}